// Function to dynamically add Google Font link to the <head> and wait until it's loaded
const CUSTOM_FONTS = ["Futura", "Graphik"];

export function loadGoogleFont(fontName: string): Promise<void> {
  if (CUSTOM_FONTS.includes(fontName)) {
    console.log(
      `The font ${fontName} is a custom font and doesn't require loading from Google Fonts.`
    );
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const fontUrl = `https://fonts.googleapis.com/css2?family=${fontName.replace(
      / /g,
      "+"
    )}:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap`;

    const link = document.createElement("link");
    link.href = fontUrl;
    link.rel = "stylesheet";
    link.onload = () => resolve();
    link.onerror = () => reject(new Error(`Failed to load font: ${fontName}`));
    document.head.appendChild(link);
  });
}

// Function to extract font-family name from the URL
export function extractFontFamily(url: string) {
  const match = url.match(/family=([^:&,]+)/);
  if (match) {
    return match[1].replace(/\+/g, " ");
  }
  return url;
}

// Apply the font to all text elements, including html and body
export async function applyFontFromConfig(fontUrl: string) {
  try {
    await loadGoogleFont(fontUrl);
    const fontFamily = extractFontFamily(fontUrl);

    const style = document.createElement("style");
    style.innerHTML = `
      * {
        font-family: "${fontFamily}", sans-serif !important;
      }

      .fa-regular, .far, .fa, [class^="fa-"], [class*=" fa-"], svg text {
        font-family: "${fontFamily}", sans-serif !important;
        font-weight: 400;
      }

      :host, :root {
        --fa-font-solid: normal 900 1em/1 "${fontFamily}", sans-serif !important;
      }

      ::before, ::after {
        font-family: "${fontFamily}", sans-serif !important;
      }
    `;
    document.head.appendChild(style);

    document.documentElement.style.fontFamily = `${fontFamily}, sans-serif !important`;
    document.body.style.fontFamily = `${fontFamily}, sans-serif !important`;
  } catch (error) {
    console.error("Failed to load the font:", error);
  }
}
