import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";

import { Error as ErrorIcon } from "@mui/icons-material";

import { brandsArray } from "../../configs/configLoader";
import { font, welcomeScreenUI } from "../../configs";

import { VALIDATION_RULES } from "../../constants/modal";
import { handleMoveToNextInput, isPhysicalAndroidDevice } from "../../utils";

import "./index.css";

interface TextFieldComponentProps {
  id: string;
  value?: string;
  unit: string;
  unitWeight: string;
  placeholder: string;
  handleChange: (e: any) => void;
  handleClick?: () => void;
  setBlurSignal: React.Dispatch<React.SetStateAction<string>>;
  setNeedValidate: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
  errorType: string;
  index: number;
  inputRefs: React.RefObject<HTMLInputElement>[];
  feetValue?: string;
  disableContinue: boolean;
  nextStep: () => void;
}

const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  id,
  value,
  unit,
  unitWeight,
  placeholder,
  handleChange,
  handleClick,
  setBlurSignal,
  setNeedValidate,
  error,
  errorType,
  index,
  inputRefs,
  feetValue,
  disableContinue,
  nextStep,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const isMediumHeightRelative = useMediaQuery({
    maxHeight: 678,
    minWidth: 767,
  });

  const isSmallHeightRelative = useMediaQuery({
    maxHeight: 590,
    minWidth: 767,
  });

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const timeoutRef = useRef<number | null>(null);

  const isAndroid = isPhysicalAndroidDevice();

  const inputStyleSelector = welcomeScreenUI.input_fields.value;

  const borderColor = error ? "#DA3939" : inputStyleSelector.empty.fontColor;
  const borderColorValue =
    !inputStyleSelector.borderBottom && isMobile
      ? "transparent"
      : error && errorType === id
      ? "#DA3939"
      : inputStyleSelector.borderColor;

  const isSportyAndRich = brandDefined?.name === "Sporty & Rich";
  const inputStyles = {
    backgroundColor: inputStyleSelector.empty.backgroundColor,
    borderColor: borderColorValue,
    borderRadius: isMobile
      ? inputStyleSelector?.mobileBorderRadius
      : inputStyleSelector.borderRadius,
    borderWidth: "1px",
    color: !value
      ? inputStyleSelector.empty.fontColor
      : error && errorType === id
      ? "#DA3939"
      : inputStyleSelector.filled.fontColor,
    borderBottomColor:
      error && errorType === id ? "#DA3939" : inputStyleSelector.borderColor,
    fontSize:
      (isMediumHeightRelative && !isSportyAndRich) ||
      (isSmallHeightRelative && isSportyAndRich)
        ? `calc(${inputStyleSelector.empty.fontSize} - 2px)`
        : inputStyleSelector.empty.fontSize,
    marginBottom:
      inputStyleSelector.marginBottom as React.CSSProperties["marginBottom"],
    padding: isMobile
      ? "12px 20px"
      : (inputStyleSelector.padding as React.CSSProperties["padding"]),
    borderBottom:
      brandDefined?.name !== "SRP" && inputStyleSelector.borderBottom
        ? `1px solid ${
            error && errorType === id
              ? "#DA3939"
              : inputStyleSelector.borderColor
          }`
        : "none",
    fontWeight: (!value
      ? inputStyleSelector.empty.fontWeight
      : inputStyleSelector.filled
          .fontWeight) as React.CSSProperties["fontWeight"],
    border:
      brandDefined?.name === "KENZO"
        ? "none"
        : brandDefined?.name === "SRP"
        ? `1px solid ${borderColorValue}`
        : "",
  };

  const handleFocus = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (isMobile) {
      setBlurSignal(error ? id : "");
      setNeedValidate(true);

      if (brandDefined?.name !== "Lacoste")
        e.target.scrollIntoView({ behavior: "smooth", block: "center" });
      const popup = document.querySelector(".drawer-popup") as HTMLElement;
      if (popup) {
        popup.style.position = "fixed";
        popup.style.zIndex = "999";
        popup.style.bottom = "0";
      }
    }
  };

  const handleBlur = (e: any) => {
    e.stopPropagation();

    setBlurSignal(error ? id : "");
    setNeedValidate(true);

    if (isMobile) {
      const popup = document.querySelector(".drawer-popup") as HTMLElement;
      if (popup) {
        popup.style.bottom = "0";
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const inputValue = e.target.value;

    if (error && !inputValue) setNeedValidate(true);

    const validateNumeric = /^\d*$/.test(inputValue);
    const validateFeet = /^[0-9'"]*$/.test(inputValue);

    if (unit === "feet" ? validateFeet : validateNumeric) {
      if (inputValue) setNeedValidate(false);
      handleChange(e);
    }
  };

  useEffect(() => {
    if (value) {
      const rule = VALIDATION_RULES;

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      const timeoutTime = 1000;

      // Handling for height
      if (id === "height") {
        const heightValue = parseInt(value, 10);
        if (
          unit === "cm" &&
          heightValue >= rule.height.min &&
          heightValue <= rule.height.max
        ) {
          handleMoveToNextInput(index, unit, inputRefs);
        }
      }

      if (unit === "feet") {
        // Handling for feet
        const localFeet = value ? Number(value.replace("'", "")) : null;
        if (id === "feet" && localFeet !== null) {
          if (
            localFeet >= rule.height.feet.min &&
            localFeet <= rule.height.feet.max &&
            localFeet !== 0
          ) {
            handleMoveToNextInput(index, unit, inputRefs);
          }
        }

        // Handling for inches
        const localInches = value ? Number(value.replace(`"`, "")) : null;
        if (id === "inches" && localInches !== null) {
          timeoutRef.current = window.setTimeout(() => {
            const savedFeet = feetValue
              ? Number(feetValue.replace(`"`, ""))
              : null;
            if (savedFeet !== null) {
              const totalInches = savedFeet * 12 + localInches; // convert feet and inches to total inches
              const minHeightInches =
                rule.height.feet.min * 12 + rule.height.feet.max; // 4 feet 5 inches = 53 inches
              const maxHeightInches =
                rule.height.inches.min * 12 + rule.height.inches.max; // 6 feet 9 inches = 81 inches
              if (
                totalInches >= minHeightInches &&
                totalInches <= maxHeightInches &&
                savedFeet !== 0 &&
                localInches !== 0
              ) {
                handleMoveToNextInput(index, unit, inputRefs);
              }
            }
          }, timeoutTime);
        }
      }

      // Handling for weight
      if (id === "weight") {
        const localWeight = Number(value.split(" ")[0]);
        if (
          (unitWeight === "kg" &&
            localWeight >= rule.weight.kg.min &&
            localWeight <= rule.weight.kg.max &&
            localWeight !== 0) ||
          (unitWeight === "lbs" &&
            localWeight >= rule.weight.lbs.min &&
            localWeight <= rule.weight.lbs.max &&
            localWeight !== 0)
        ) {
          handleMoveToNextInput(index, unit, inputRefs);
        }
      }

      // Handling for age
      if (id === "age") {
        const localAge = Number(value.split(" ")[0]);
        if (
          localAge >= rule.age.min &&
          localAge <= rule.age.max &&
          localAge !== 0
        ) {
          handleMoveToNextInput(index, unit, inputRefs);
        }
      }
    }

    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error]);

  useEffect(() => {
    if (errorType === id) {
      const inputElement = document.getElementById(id);

      if (inputElement) {
        const styleElement = document.createElement("style");

        const fontSize = inputStyles.fontSize;
        const baseFontSize = 16;
        const scale = parseFloat(fontSize) / baseFontSize;

        if (isMobile) {
          styleElement.innerHTML = `
          #${id}{
            border-bottom: 1px solid ${borderColor};
          }
          #${id}::placeholder {
            color: ${borderColor};
            opacity: 0.5;
            transform: scale(${scale});
            transform-origin: left;
          }
      `;
        } else {
          styleElement.innerHTML = `
          #${id}{
            border-bottom: 1px solid ${borderColor};
          }
          #${id}::placeholder {
            color: ${borderColor};
            opacity: 0.5;
          }
      `;
        }

        document.head.appendChild(styleElement);

        return () => {
          document.head.removeChild(styleElement);
        };
      }
    }
  }, [isMobile, id, errorType, borderColor, inputStyles.fontSize]);

  useEffect(() => {
    if (isMobile && brandDefined?.name === "Lacoste") {
      const inputs = document.querySelectorAll("input");

      inputs.forEach((input) => {
        input.addEventListener("touchstart", () => {
          input.focus({ preventScroll: true });
        });
      });
    } else {
      document.addEventListener("touchstart", (e) => {
        const target = e.target as HTMLElement;

        if (
          target &&
          (target.tagName === "INPUT" || target.tagName === "TEXTAREA")
        ) {
          e.preventDefault();
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, brandDefined]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (e.key === "Tab") {
      e.preventDefault();
      handleMoveToNextInput(index, unit, inputRefs, true);

      const targetInput = inputRefs[index].current;
      const genderBlock = document.querySelector(
        ".gender-buttons"
      ) as HTMLElement;
      const elem = document.activeElement as HTMLElement;

      if (index === 4 && targetInput && genderBlock) {
        targetInput.blur();
        elem?.blur();
        genderBlock?.focus();
      }
    }

    if (e.key === "Enter") {
      if (!disableContinue) {
        nextStep();

        const elem = document.activeElement as HTMLElement;
        elem?.blur();
      }
    }
  };

  return (
    <>
      <input
        id={id}
        type="text"
        ref={inputRefs[index]}
        tabIndex={index}
        placeholder={placeholder}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={(e) => {
          e.stopPropagation();
          handleClick && handleClick();
        }}
        pattern="[0-9]*"
        style={{
          ...inputStyles,
          marginBottom:
            !isMobile && brandDefined?.name !== "SRP"
              ? inputStyles.marginBottom
              : !isMobile && brandDefined?.name === "SRP"
              ? 0
              : 0,
          borderBottom:
            inputStyleSelector.borderBottom === "none"
              ? "none"
              : `1px solid ${borderColor}`,
          border:
            inputStyleSelector.borderBottom === "none"
              ? "none"
              : inputStyles.border,
          width: isMobile ? "calc(100% - 42px)" : "100%",
          maxWidth: isMobile
            ? "calc(100% - 42px)"
            : inputStyleSelector.borderBottom === "none" ||
              brandDefined?.name === "SRP"
            ? "calc(100% - 20px)"
            : "100%",
          position: "relative",
          outline: "none",
          fontFamily:
            !isAndroid && font !== "Futura"
              ? `${font}, sans-serif !important`
              : "",
        }}
      />
      {isMobile && error && (
        <div
          style={{
            position: "absolute",
            right: id === "age" ? "35px" : "10px",
            top: id === "age" ? "74%" : "46%",
            transform: "translateY(-50%)",
          }}
        >
          <ErrorIcon style={{ color: "#DA3939" }} />
        </div>
      )}
    </>
  );
};

export default TextFieldComponent;
