import { FC } from "react";
import { useMediaQuery } from "react-responsive";

import StepIntroDesktop from "../../../StepIntroDesktop";
import StepDeviceSelect from "../../../StepDeviceSelect";
import StepQRCode from "../../../StepQRCode";
import StepBelly from "../../../StepBelly";
import StepTorse from "../../../StepTorse";
import StepCuisses from "../../../StepCuisses";
import StepBreasts from "../../../StepBreasts";
import ResultError from "../../../ResultError";
import Result from "../../../Result";
import ConsentScreen from "../../../ConsentScreen";

import { GENDERS, MODAL_STEPS } from "../../../../constants/modal";
import { newMeasure } from "../../../../api/endpoints";
import { SizeDataType } from "../../../../types/result";
import {
  removeLocalStore,
  setLocalStore,
} from "../../../../store/localStoreUtils";
import {
  font,
  subtitlesStyles,
  titleStyles,
  uxGender,
  uxRoute,
} from "../../../../configs";
import { useUserContext } from "../../../../store/userContext";
import { handleAnalytics } from "../../../../utils/tracking";

import "./index.css";

interface IPropsDrawerSteps {
  step: any;
  error: any;
  height: string;
  unit: string;
  feet: string;
  inches: string;
  unitWeight: string;
  weight?: string | undefined;
  age: string;
  selectedGender: string;
  disableContinue: boolean;
  bellyValue: any;
  torsoValue: any;
  cuissesValue: any;
  sizeValue: any;
  cupValue: any;
  isShoesProduct: boolean;
  sendQuestionAnswers: (isSkipResult?: boolean) => void;
  handleFieldChange: (e: any, type: string, blurSignal: string) => void;
  handleUnitChange: (newUnit: string) => void;
  handleUnitWeightChange: (newUnit: string) => void;
  handleGenderSelect: (newUnit: string) => void;
  setNeedValidate: React.Dispatch<React.SetStateAction<boolean>>;
  setRecommendedSize: React.Dispatch<any>;
  reducedResult: any;
  selectedRoute: string;
  setSelectedRoute: React.Dispatch<React.SetStateAction<string>>;
  sizeCountry: string;
  setSizeCountry: React.Dispatch<React.SetStateAction<string>>;
  productStockData: any;
  similarProducts: any;
  isSizeUnavailable: boolean;
  selectedSize: SizeDataType | null;
  setSelectedSize: React.Dispatch<React.SetStateAction<SizeDataType | null>>;
  setSimilarProducts: React.Dispatch<any>;
  setStep: (
    value: React.SetStateAction<{
      number: number;
    }>
  ) => void;
  setPreviousStep: React.Dispatch<
    React.SetStateAction<{
      number: number;
    } | null>
  >;
  shoeRecommendation: any;
  setShoeRecommendation: React.Dispatch<any>;
  restart: () => void;
  setIsScanError: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerSteps: FC<IPropsDrawerSteps> = ({
  step,
  error,
  height,
  unit,
  feet,
  inches,
  unitWeight,
  weight,
  age,
  selectedGender,
  disableContinue,
  bellyValue,
  torsoValue,
  cuissesValue,
  sizeValue,
  cupValue,
  isShoesProduct,
  sendQuestionAnswers,
  handleFieldChange,
  handleUnitChange,
  handleUnitWeightChange,
  handleGenderSelect,
  setNeedValidate,
  setRecommendedSize,
  reducedResult,
  selectedRoute,
  setSelectedRoute,
  sizeCountry,
  setSizeCountry,
  productStockData,
  similarProducts,
  isSizeUnavailable,
  selectedSize,
  setSelectedSize,
  setStep,
  setPreviousStep,
  restart,
  setSimilarProducts,
  shoeRecommendation,
  setShoeRecommendation,
  setIsScanError,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const userContext = useUserContext() || undefined;

  const urlParameters = new URLSearchParams(window.location.search);
  const product_id = urlParameters.get("product_id");
  const domain = urlParameters.get("domain");

  const titlesStyles = {
    color: titleStyles.color as React.CSSProperties["color"],
    fontSize: titleStyles.fontSize as React.CSSProperties["fontSize"],
    fontWeight: titleStyles.fontWeight,
    textAlign: titleStyles.textAlign as React.CSSProperties["textAlign"],
    textTransform:
      titleStyles.textTransform as React.CSSProperties["textTransform"],
    justifyContent:
      titleStyles.textAlign as React.CSSProperties["justifyContent"],
    fontFamily: isMobile
      ? `${font}, sans-serif`
      : `${font}, sans-serif !important`,
  };

  const subtitleStyles = {
    color: subtitlesStyles.color as React.CSSProperties["color"],
    fontSize: subtitlesStyles.fontSize as React.CSSProperties["fontSize"],
    fontWeight: subtitlesStyles.fontWeight as React.CSSProperties["fontWeight"],
    textAlign: subtitlesStyles.textAlign as React.CSSProperties["textAlign"],
    textTransform:
      subtitlesStyles.textTransform as React.CSSProperties["textTransform"],
    justifyContent:
      subtitlesStyles.textAlign as React.CSSProperties["justifyContent"],
    fontFamily: isMobile
      ? `${font}, sans-serif`
      : `${font}, sans-serif !important`,
  };

  const handleRouteEvent = async (type: string) => {
    if (type === "question_pushScan") {
      const resultNewMeasure = await newMeasure("scan");
      if (resultNewMeasure) setStep(MODAL_STEPS.QR_CODE);
    }

    if (type === "question_only") {
      setStep(MODAL_STEPS.BELLY);
    }

    setSelectedRoute(type);
  };

  const defaultStep =
    isMobile && uxGender
      ? MODAL_STEPS.INTRO_MOBILE
      : isMobile
      ? MODAL_STEPS.GENDER
      : MODAL_STEPS.INTRO;

  const handleNextStep = async () => {
    if (isShoesProduct) {
      setStep(MODAL_STEPS.QR_CODE);
      setIsScanError(false);
    } else {
      removeLocalStore("mid");
      removeLocalStore("uid");
      removeLocalStore("gender");

      if (userContext) {
        console.log("there");
        userContext
          .newUser(domain)
          .then(async () => {
            if (uxRoute === "none") {
              restart();
            } else {
              if (selectedRoute === "question_pushScan") {
                const resultNewMeasure = await newMeasure("scan");
                if (resultNewMeasure) setStep(MODAL_STEPS.QR_CODE);

                handleAnalytics("action", "error_restart", {
                  key: "route_selected",
                  value: "scan",
                });
              }

              if (selectedRoute === "question_only") {
                const resultNewMeasure = await newMeasure("question");
                if (resultNewMeasure) setStep(defaultStep);

                handleAnalytics("action", "error_restart", {
                  key: "route_selected",
                  value: "question",
                });
              }
            }
          })
          .catch((e: any) => console.log(e));
      }
    }
  };

  const handleSaveUserInfo = async () => {
    setLocalStore(
      "user",
      JSON.stringify({
        gender: selectedGender,
        age,
        height,
        weight,
      })
    );
  };

  const clearMid = () => {
    window.parent.postMessage({ action: "clearMid" }, "*");
  };

  const isFirstIntroScreen =
    step?.number === MODAL_STEPS.INTRO.number ||
    step?.number === MODAL_STEPS.GENDER.number ||
    step?.number === MODAL_STEPS.INTRO_MOBILE.number;

  const preselectedRoute =
    uxRoute === "question_pushScan" || uxRoute === "question_only";

  return (
    <>
      {step?.number === MODAL_STEPS.CONSENT.number && (
        <ConsentScreen
          nextStep={() => {
            handleAnalytics("action", "consent_submit", null);
            setStep(isMobile ? MODAL_STEPS.GENDER : MODAL_STEPS.INTRO);
          }}
          clearMid={clearMid}
        />
      )}
      {isFirstIntroScreen && (
        <StepIntroDesktop
          step={step}
          error={error}
          height={height}
          unit={unit}
          feet={feet}
          inches={inches}
          unitWeight={unitWeight}
          weight={weight}
          age={age}
          selectedGender={selectedGender}
          disableContinue={disableContinue}
          handleFieldChange={handleFieldChange}
          handleUnitChange={handleUnitChange}
          handleUnitWeightChange={handleUnitWeightChange}
          handleGenderSelect={handleGenderSelect}
          setNeedValidate={setNeedValidate}
          subtitleStyles={subtitleStyles}
          clearMid={clearMid}
          isShoesProduct={isShoesProduct}
          nextStepShoes={() => setStep(MODAL_STEPS.BREASTS)}
          setShoeRecommendation={setShoeRecommendation}
          setStep={setStep}
          setRecommendedSize={setRecommendedSize}
          productStockData={productStockData}
          nextStepGender={async () => {
            if (
              !uxGender &&
              isMobile &&
              step?.number === MODAL_STEPS.GENDER.number
            ) {
              setStep(MODAL_STEPS.INTRO_MOBILE);
            }
          }}
          nextStep={async () => {
            if (uxRoute === "none") {
              const resultNewMeasure = await newMeasure("question");
              if (resultNewMeasure) {
                if (selectedGender === GENDERS.M) {
                  sendQuestionAnswers();
                } else if (selectedGender === GENDERS.F) {
                  setStep(MODAL_STEPS.BREASTS);
                }
              }
            }

            if (["question_pushScan", "question_only"].includes(uxRoute)) {
              await handleRouteEvent(uxRoute);
              setSelectedRoute(uxRoute);
            }

            // desktop first screen
            if (
              uxRoute === "" &&
              [
                MODAL_STEPS.INTRO.number,
                MODAL_STEPS.INTRO_MOBILE.number,
              ].includes(step?.number) &&
              !preselectedRoute
            ) {
              setStep(MODAL_STEPS.DEVICE_SELECT);
            }

            handleSaveUserInfo();
          }}
        />
      )}
      {step?.number === MODAL_STEPS.DEVICE_SELECT.number && (
        <StepDeviceSelect
          step={step}
          nextPhotoStep={async () => {
            await handleRouteEvent("question_pushScan");
            setSelectedRoute("question_pushScan");
          }}
          nextQuestionsStep={async () => {
            await handleRouteEvent("question_only");
            setSelectedRoute("question_only");
          }}
        />
      )}
      {step?.number === MODAL_STEPS.QR_CODE.number && (
        <StepQRCode
          step={step}
          pid={product_id}
          gender={selectedGender}
          setRecommendedSize={setRecommendedSize}
          titleStyles={titlesStyles}
          subtitleStyles={subtitleStyles}
          productStockData={productStockData}
          isShoesProduct={isShoesProduct}
          setShoeRecommendation={setShoeRecommendation}
          skipToResult={() => setStep(MODAL_STEPS.RESULT)}
          handleError={() => setStep(MODAL_STEPS.ERROR)}
          setIsScanError={setIsScanError}
        />
      )}
      {step?.number === MODAL_STEPS.BELLY.number && (
        <StepBelly
          step={step}
          gender={selectedGender}
          nextStep={() => setStep(MODAL_STEPS.TORSO)}
          setStep={setStep}
          setPreviousStep={setPreviousStep}
          value={bellyValue}
          subtitleStyles={subtitleStyles}
        />
      )}
      {step?.number === MODAL_STEPS.TORSO.number && (
        <StepTorse
          step={step}
          gender={selectedGender}
          nextStep={() => {
            if (selectedGender === GENDERS.M) {
              setStep(MODAL_STEPS.CUISSES);
            } else {
              setStep(MODAL_STEPS.BREASTS);
            }
          }}
          setStep={setStep}
          setPreviousStep={setPreviousStep}
          value={torsoValue}
          subtitleStyles={subtitleStyles}
        />
      )}
      {step?.number === MODAL_STEPS.CUISSES.number && (
        <StepCuisses
          step={step}
          gender={selectedGender}
          value={cuissesValue}
          nextStep={async () => {
            await newMeasure("question");
            sendQuestionAnswers();
          }}
          setStep={setStep}
          setPreviousStep={setPreviousStep}
          subtitleStyles={subtitleStyles}
        />
      )}
      {step?.number === MODAL_STEPS.BREASTS.number && (
        <StepBreasts
          step={step}
          value_1={sizeValue}
          value_2={cupValue}
          nextStep={async () => {
            await newMeasure("question");
            sendQuestionAnswers();
          }}
          skipResult={async () => {
            await newMeasure("question");
            sendQuestionAnswers(true);
          }}
          sizeCountry={sizeCountry}
          setSizeCountry={setSizeCountry}
          subtitleStyles={subtitleStyles}
          isShoesProduct={isShoesProduct}
        />
      )}
      {step?.number === MODAL_STEPS.ERROR.number && (
        <ResultError
          step={step}
          nextStep={async () => await handleNextStep()}
          isShoesProduct={isShoesProduct}
        />
      )}
      {(step?.number === MODAL_STEPS.RESULT.number ||
        step?.number === MODAL_STEPS.UNAVAILABLE_RESULT.number) && (
        <Result
          step={step}
          reducedResult={reducedResult}
          selectedGender={selectedGender}
          productStockData={productStockData}
          similarProducts={similarProducts}
          isSizeUnavailable={isSizeUnavailable}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
          restart={restart}
          setSimilarProducts={setSimilarProducts}
          isShoesProduct={isShoesProduct}
          shoeRecommendation={shoeRecommendation}
        />
      )}
    </>
  );
};

export default DrawerSteps;
