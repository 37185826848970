import { useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/system";

import TextFieldComponent from "../../../TextField";
import UnitOptions from "../../../UnitOptions";

import {
  font,
  welcomeScreenUI,
  titleStyles as titleStylesBase,
} from "../../../../configs";
import {
  capitalizeFirstLetter,
  isPhysicalAndroidDevice,
} from "../../../../utils";
import { brandsArray } from "../../../../configs/configLoader";
import { handleAnalytics } from "../../../../utils/tracking";

import "./index.css";

interface UserInfoBlockProps {
  error: any;
  height: string;
  unit: string;
  feet: string;
  inches: string;
  unitWeight: string;
  weight?: string | undefined;
  age: string;
  handleFieldChange: (e: any, type: string, blurSignal: string) => void;
  handleUnitChange: (newUnit: string) => void;
  handleUnitWeightChange: (newUnit: string) => void;
  setNeedValidate: React.Dispatch<React.SetStateAction<boolean>>;
  titlesStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
  disableContinue: boolean;
  nextStep: () => void;
}

const UserInfoBlock: React.FC<UserInfoBlockProps> = ({
  error,
  height,
  unit,
  feet,
  inches,
  unitWeight,
  weight,
  age,
  handleFieldChange,
  handleUnitChange,
  handleUnitWeightChange,
  setNeedValidate,
  titlesStyles,
  disableContinue,
  nextStep,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { t } = useTranslation("components/intro");

  const [valueAge, setValueAge] = useState<string>(age);
  const [blurSignal, setBlurSignal] = useState("");

  const fontFamily = `${font}, sans-serif`;

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const isAndroid = isPhysicalAndroidDevice();

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  return (
    <>
      {isMobile && brandDefined?.name !== "SRP" ? (
        <WrapperBlock>
          <label
            htmlFor="height"
            style={{
              ...titlesStyles,
              color: error.height.error
                ? "#DA3939"
                : welcomeScreenUI.input_fields.title.fontColor,
              textTransform:
                titlesStyles.textTransform === "capitalize"
                  ? "none"
                  : (titleStylesBase.textTransform as React.CSSProperties["textTransform"]),
            }}
          >
            {titlesStyles.textTransform === "capitalize"
              ? capitalizeFirstLetter(t("height.title"))
              : t("height.title")}
          </label>
          <Wrapper>
            <ToggleButtonGroup
              value={unit}
              exclusive
              onChange={(_, newUnit) => handleUnitChange(newUnit)}
            >
              <StyledToggleButton
                value="cm"
                style={{
                  fontFamily,
                }}
              >
                <span
                  style={{
                    position: "relative",
                    bottom: isAndroid && font === "Futura" ? "2px" : 0,
                  }}
                >
                  cm
                </span>
              </StyledToggleButton>
              <StyledToggleButton value="feet" style={{ fontFamily }}>
                <span
                  style={{
                    position: "relative",
                    bottom: isAndroid && font === "Futura" ? "2px" : 0,
                  }}
                >
                  feet
                </span>
              </StyledToggleButton>
            </ToggleButtonGroup>
          </Wrapper>
        </WrapperBlock>
      ) : (
        <label
          htmlFor="height"
          style={{
            ...titlesStyles,
            color: error.height.error
              ? "#DA3939"
              : welcomeScreenUI.input_fields.title.fontColor,
            textTransform:
              titlesStyles.textTransform === "capitalize"
                ? "none"
                : (titleStylesBase.textTransform as React.CSSProperties["textTransform"]),
          }}
        >
          {titlesStyles.textTransform === "capitalize"
            ? capitalizeFirstLetter(t("height.title"))
            : t("height.title")}
        </label>
      )}
      <div className="input-container">
        {(!isMobile || (isMobile && brandDefined?.name === "SRP")) && (
          <UnitOptions
            option1="cm"
            option2="feet"
            unit={unit}
            handleUnitChange={handleUnitChange}
          />
        )}

        {unit === "cm" ? (
          <>
            <TextFieldComponent
              id="height"
              value={height}
              unit={unit}
              unitWeight={unitWeight}
              placeholder={t("height.placeholder")}
              handleChange={(e: any) =>
                handleFieldChange(e, "height", blurSignal)
              }
              handleClick={() => handleAnalytics("action", "height_click", null)}
              setBlurSignal={setBlurSignal}
              setNeedValidate={setNeedValidate}
              error={error.height.error}
              errorType="height"
              index={0}
              inputRefs={inputRefs}
              disableContinue={disableContinue}
              nextStep={nextStep}
            />
            <div style={{ height: "10px" }}>
              {error.height.error && (
                <span className="baseErrorTitle">{error.height.message}</span>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                gap: isMobile ? "10px" : "20px",
              }}
            >
              <TextFieldComponent
                id="feet"
                value={feet === "" ? "" : `${feet}'`}
                unit={unit}
                unitWeight={unitWeight}
                placeholder={t("height.placeholderfeet")}
                handleChange={(event: any) => {
                  const newValue = event.target.value
                    ? event.target.value.replace(/\D/g, "")
                    : "";
                  handleFieldChange(newValue, "feet", blurSignal);

                  const cursorPosition = event.target.selectionStart;

                  setTimeout(() => {
                    event.target.setSelectionRange(
                      cursorPosition,
                      cursorPosition
                    );
                  }, 0);
                }}
                setBlurSignal={setBlurSignal}
                setNeedValidate={setNeedValidate}
                error={error.height.error}
                errorType="feet"
                index={1}
                inputRefs={inputRefs}
                disableContinue={disableContinue}
                nextStep={nextStep}
              />
              <TextFieldComponent
                id="inches"
                value={inches === "" ? "" : `${inches}"`}
                unit={unit}
                feetValue={feet}
                unitWeight={unitWeight}
                placeholder={t("height.placeholderinches")}
                handleChange={(event: any) => {
                  const newValue = event.target.value
                    ? event.target.value.replace(/\D/g, "")
                    : "";
                  handleFieldChange(newValue, "inches", blurSignal);

                  const cursorPosition = event.target.selectionStart;

                  setTimeout(() => {
                    event.target.setSelectionRange(
                      cursorPosition,
                      cursorPosition
                    );
                  }, 0);
                }}
                setBlurSignal={setBlurSignal}
                setNeedValidate={setNeedValidate}
                error={error.height.error}
                errorType="inches"
                index={2}
                inputRefs={inputRefs}
                disableContinue={disableContinue}
                nextStep={nextStep}
              />
            </div>
            <div style={{ height: "10px" }}>
              {error.height.error && (
                <span className="baseErrorTitle">{error.height.message}</span>
              )}
            </div>
          </>
        )}
      </div>
      {isMobile && brandDefined?.name !== "SRP" ? (
        <WrapperBlock style={{ marginTop: "5px" }}>
          <label
            htmlFor="weight"
            style={{
              ...titlesStyles,
              color: error.weight.error
                ? "#DA3939"
                : welcomeScreenUI.input_fields.title.fontColor,
              textTransform:
                titlesStyles.textTransform === "capitalize"
                  ? "none"
                  : (titleStylesBase.textTransform as React.CSSProperties["textTransform"]),
            }}
          >
            {titlesStyles.textTransform === "capitalize"
              ? capitalizeFirstLetter(t("weight.title"))
              : t("weight.title")}
          </label>
          <Wrapper>
            <ToggleButtonGroup
              value={unitWeight}
              exclusive
              onChange={(_, newUnitWeight) =>
                handleUnitWeightChange(newUnitWeight)
              }
            >
              <StyledToggleButton value="kg" style={{ fontFamily }}>
                <span
                  style={{
                    position: "relative",
                    bottom: isAndroid && font === "Futura" ? "2px" : 0,
                  }}
                >
                  kg
                </span>
              </StyledToggleButton>
              <StyledToggleButton value="lbs" style={{ fontFamily }}>
                <span
                  style={{
                    position: "relative",
                    bottom: isAndroid && font === "Futura" ? "2px" : 0,
                  }}
                >
                  lbs
                </span>
              </StyledToggleButton>
            </ToggleButtonGroup>
          </Wrapper>
        </WrapperBlock>
      ) : (
        <label
          htmlFor="weight"
          style={{
            ...titlesStyles,
            color: error.weight.error
              ? "#DA3939"
              : welcomeScreenUI.input_fields.title.fontColor,
            textTransform:
              titlesStyles.textTransform === "capitalize"
                ? "none"
                : (titleStylesBase.textTransform as React.CSSProperties["textTransform"]),
          }}
        >
          {titlesStyles.textTransform === "capitalize"
            ? capitalizeFirstLetter(t("weight.title"))
            : t("weight.title")}
        </label>
      )}
      <div className="input-container">
        {(!isMobile || (isMobile && brandDefined?.name === "SRP")) && (
          <UnitOptions
            option1="kg"
            option2="lbs"
            unit={unitWeight}
            handleUnitChange={handleUnitWeightChange}
          />
        )}
        <TextFieldComponent
          id="weight"
          value={weight}
          unit={unit}
          unitWeight={unitWeight}
          placeholder={
            unitWeight === "kg"
              ? t("weight.placeholderKG")
              : t("weight.placeholderLBS")
          }
          handleChange={(e: any) => handleFieldChange(e, "weight", blurSignal)}
          handleClick={() => handleAnalytics("action", "weight_click", null)}
          setBlurSignal={setBlurSignal}
          setNeedValidate={setNeedValidate}
          error={error.weight.error}
          errorType="weight"
          index={3}
          inputRefs={inputRefs}
          disableContinue={disableContinue}
          nextStep={nextStep}
        />
        <div style={{ height: "10px" }}>
          {error.weight.error && (
            <span className="baseErrorTitle">{error.weight.message}</span>
          )}
        </div>
      </div>
      <label
        htmlFor="age"
        style={{
          ...titlesStyles,
          color: error.age.error
            ? "#DA3939"
            : welcomeScreenUI.input_fields.title.fontColor,
          textTransform:
            titlesStyles.textTransform === "capitalize"
              ? "none"
              : (titleStylesBase.textTransform as React.CSSProperties["textTransform"]),
          marginTop: isMobile ? "10px" : "0px",
          marginBottom:
            isAndroid && brandDefined?.name === "Sporty & Rich" ? "10px" : "0",
        }}
      >
        {titlesStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("age.title"))
          : t("age.title")}
      </label>
      <TextFieldComponent
        id="age"
        value={valueAge || ""}
        unit={unit}
        unitWeight={unitWeight}
        setBlurSignal={setBlurSignal}
        setNeedValidate={setNeedValidate}
        placeholder={t("age.placeholder")}
        index={4}
        inputRefs={inputRefs}
        handleChange={(event: any) => {
          const newValue = event.target.value.replace(/\D/g, "");

          setValueAge(newValue);

          handleFieldChange(newValue, "age", blurSignal);

          const cursorPosition = event.target.selectionStart;

          setTimeout(() => {
            event.target.setSelectionRange(cursorPosition, cursorPosition);
          }, 0);
        }}
        handleClick={() => handleAnalytics("action", "age_click", null)}
        disableContinue={disableContinue}
        nextStep={nextStep}
        error={error.age.error}
        errorType="age"
      />
      <div style={{ height: "2px", position: "relative", bottom: "10px" }}>
        {error.age.error && (
          <span className="ageErrorTitle">{error.age.message}</span>
        )}
      </div>
    </>
  );
};

const WrapperBlock = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Wrapper = styled("div")({
  display: "inline-block",
  backgroundColor: "#F7F7F7",
  borderRadius: "27px",
  padding: "1px 5px",
});

const StyledToggleButton = styled(ToggleButton)({
  width: "28px",
  height: "15px",
  fontSize: "8px",
  fontWeight: 500,
  textTransform: "none",
  padding: "8px 10px",
  position: "relative",
  bottom: "1px",
  "&.Mui-selected": {
    backgroundColor: welcomeScreenUI.units.activeColor,
    color: "#FFFFFF",
    borderColor: "transparent",
    borderRadius: "17px",
    margin: "2px 0",
  },
  "&.Mui-selected: hover": {
    backgroundColor: welcomeScreenUI.units.activeColor,
    color: "#FFFFFF",
    borderColor: "transparent",
    borderRadius: "17px",
    margin: "2px 0",
  },
  "&:not(.Mui-selected)": {
    color: welcomeScreenUI.units.activeColor,
    backgroundColor: "transparent",
    borderColor: "transparent",
    borderRadius: "17px",
    margin: "2px 0",
  },
});

export default UserInfoBlock;
