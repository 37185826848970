// import { useMediaQuery } from "react-responsive";
// import { useTranslation } from "react-i18next";

// import {
//   welcomeScreenUI,
//   titleStyles as titleStylesBase,
// } from "../../../../configs";
// import { capitalizeFirstLetter } from "../../../../utils";
// import { brandsArray } from "../../../../configs/configLoader";

// import {
//   MODAL_STEPS,
//   // shoesAllBrands,
//   // shoesPopularBrands,
// } from "../../../../constants/modal";
// import MeasuredBy from "../../../molecules/MeasuredBy";
// import nikeIcon from "../../../../assets/brands/nike.svg";

import { MODAL_STEPS } from "../../../../constants/modal";
import StepQRCode from "../../../StepQRCode";

import "./index.css";

interface IPropsShoesComponent {
  step: any;
  titlesStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
  descriptionStyles: any;
  subtitleStyles: any;
  nextStepShoes: () => void;
  selectedGender: string;
  setStep: (
    value: React.SetStateAction<{
      number: number;
    }>
  ) => void;
  setRecommendedSize: React.Dispatch<any>;
  setShoeRecommendation: React.Dispatch<any>;
  productStockData: any;
}

const ShoesComponent: React.FC<IPropsShoesComponent> = ({
  step,
  titlesStyles,
  descriptionStyles,
  subtitleStyles,
  nextStepShoes,
  selectedGender,
  setStep,
  setRecommendedSize,
  setShoeRecommendation,
  productStockData,
}) => {
  // const isMobile = useMediaQuery({ maxWidth: 767 });

  // const { t } = useTranslation("components/intro");

  const urlParameters = new URLSearchParams(window.location.search);
  // const domain = urlParameters.get("domain");
  const product_id = urlParameters.get("product_id");

  // const brandDefined = brandsArray.find((brand) =>
  //   domain ? brand.domains.includes(domain) : null
  // );

  // const isMobileSRP = isMobile && brandDefined?.name === "SRP";

  const handleContent = () => {
    return (
      <StepQRCode
        step={step}
        pid={product_id}
        gender={selectedGender}
        setRecommendedSize={setRecommendedSize}
        titleStyles={titlesStyles}
        subtitleStyles={subtitleStyles}
        productStockData={productStockData}
        skipToResult={() => setStep(MODAL_STEPS.RESULT)}
        setShoeRecommendation={setShoeRecommendation}
        handleError={() => setStep(MODAL_STEPS.ERROR)}
        isShoesProduct
      />
    );

    // version for part 2 (shoe sizing desktop)
    // <>
    //   {!isMobile && (
    //     <span
    //       className="description"
    //       style={{
    //         ...descriptionStyles,
    //         marginTop: "5px",
    //       }}
    //     >
    //       {subtitleStyles.textTransform === "capitalize"
    //         ? capitalizeFirstLetter(t("description"))
    //         : t("description")}
    //     </span>
    //   )}
    //   {((isMobile && step?.number === MODAL_STEPS.GENDER.number) ||
    //     isMobileSRP) && (
    //     <span className="descriptionSmall" style={descriptionStyles}>
    //       {(descriptionStyles.textTransform as string) === "capitalize"
    //         ? capitalizeFirstLetter(
    //             t(brandDefined?.name === "SRP" ? "description" : "gender.title")
    //           )
    //         : t(brandDefined?.name === "SRP" ? "description" : "gender.title")}
    //     </span>
    //   )}
    //   {isMobileSRP && step?.number === MODAL_STEPS.GENDER.number && (
    //     <span className="descriptionSmall" style={titlesStyles}>
    //       {(descriptionStyles.textTransform as string) === "capitalize"
    //         ? capitalizeFirstLetter(t("gender.title"))
    //         : t("gender.title")}
    //     </span>
    //   )}
    //   <div className="brands-bar">
    //     <div className="search-bar">
    //       <input
    //         id="search-bar"
    //         type="text"
    //         placeholder="Search for a shoe brand"
    //         className="search-bar-input"
    //       />
    //     </div>
    //     <div className="brands-block">
    //       <label
    //         htmlFor="height"
    //         style={{
    //           ...titlesStyles,
    //           fontSize: "16px",
    //           color: welcomeScreenUI.input_fields.title.fontColor,
    //           textTransform:
    //             titlesStyles.textTransform === "capitalize"
    //               ? "none"
    //               : (titleStylesBase.textTransform as React.CSSProperties["textTransform"]),
    //         }}
    //       >
    //         {/* {titlesStyles.textTransform === "capitalize"
    //         ? capitalizeFirstLetter(t("height.title"))
    //         : t("height.title")} */}
    //         Popular brands
    //       </label>
    //       <div className="brands">
    //         {shoesPopularBrands?.map((brand: any, index: number) => (
    //           <div key={index} className="item" onClick={nextStepShoes}>
    //             <img alt={brand?.name} src={nikeIcon} width={32} height={32} />
    //             <p>{brand?.name}</p>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //     <div className="brands-block">
    //       <label
    //         htmlFor="height"
    //         style={{
    //           ...titlesStyles,
    //           fontSize: "16px",
    //           color: welcomeScreenUI.input_fields.title.fontColor,
    //           textTransform:
    //             titlesStyles.textTransform === "capitalize"
    //               ? "none"
    //               : (titleStylesBase.textTransform as React.CSSProperties["textTransform"]),
    //         }}
    //       >
    //         {/* {titlesStyles.textTransform === "capitalize"
    //         ? capitalizeFirstLetter(t("height.title"))
    //         : t("height.title")} */}
    //         All brands
    //       </label>
    //       <div className="brands">
    //         {shoesAllBrands?.map((brand: any, index: number) => (
    //           <div key={index} className="item" onClick={nextStepShoes}>
    //             <img alt={brand?.name} src={nikeIcon} width={32} height={32} />
    //             <p>{brand?.name}</p>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    //   <MeasuredBy step={step} />
    // </>
  };

  return handleContent();
};

export default ShoesComponent;
