import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import MeasuredBy from "../molecules/MeasuredBy";

import {
  activeStylesContinue,
  welcomeScreenUI,
  titleStyles as titleStylesBase,
  subtitlesStyles,
} from "../../configs";
import { capitalizeFirstLetter } from "../../utils";
import { brandsArray } from "../../configs/configLoader";

import "./index.css";

interface IPropsResultError {
  step: any;
  nextStep: () => void;
  isShoesProduct: boolean;
}

const ResultError: FC<IPropsResultError> = ({
  step,
  nextStep,
  isShoesProduct,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const isLargeHeightRelative = useMediaQuery({
    maxHeight: 778,
    minWidth: 767,
  });

  const { t } = useTranslation("components/results/error");

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const titleTextTransform = welcomeScreenUI.input_fields.title
    .textTransform as React.CSSProperties["textTransform"];

  const titlesStyles = {
    fontWeight: "400",
    textAlign: welcomeScreenUI.input_fields.title
      .textAlign as React.CSSProperties["textAlign"],
    textTransform:
      titleTextTransform === "capitalize" ? "none" : titleTextTransform,
    color: titleStylesBase.color as React.CSSProperties["color"],
    fontSize: "14px",
    justifyContent: welcomeScreenUI.input_fields.title
      .textAlign as React.CSSProperties["justifyContent"],
  };

  return (
    <div
      className="result-error"
      style={{
        marginTop: isShoesProduct
          ? "30px"
          : isLargeHeightRelative
          ? "30px"
          : isMobile
          ? 0
          : "40px",
      }}
    >
      <span
        className="description"
        style={{
          ...titlesStyles,
          marginTop:
            !isMobile && brandDefined?.name === "SRP"
              ? "60px"
              : isLargeHeightRelative
              ? 0
              : isMobile
              ? 0
              : "25px",
          marginBottom: isShoesProduct ? "30px" : "40px",
          textTransform: (subtitlesStyles.textTransform === "capitalize"
            ? "none"
            : subtitlesStyles.textTransform) as React.CSSProperties["textTransform"],
        }}
      >
        {isShoesProduct
          ? "Nous n’avons pas pu identifier votre taille."
          : subtitlesStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("error.description"))
          : t("error.description")}
      </span>

      <button
        type="button"
        className="continue-button"
        onClick={() => nextStep()}
        style={{
          ...activeStylesContinue,
          textTransform: isShoesProduct
            ? "capitalize"
            : activeStylesContinue.textTransform === "capitalize"
            ? "none"
            : activeStylesContinue.textTransform,
        }}
      >
        {activeStylesContinue.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("skip"))
          : t("skip")}
      </button>

      <MeasuredBy step={step} />
    </div>
  );
};

export default ResultError;
