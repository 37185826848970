import { pushAnalytics } from "../api/endpoints";
import { MODAL_STEPS } from "../constants/modal";

export const handleAnalytics = async (
  event_type: "step" | "action",
  event_name: string,
  data: {
    key: string;
    value: string | number | boolean;
  } | null
) => {
  await pushAnalytics({
    event_type,
    event_name,
    data: data
      ? {
          key: data.key,
          value: data.value,
        }
      : null,
  });
};

export const getStepByNumber = (number: number) => {
  const foundStep = Object.keys(MODAL_STEPS).find(
    (key) => MODAL_STEPS[key as keyof typeof MODAL_STEPS].number === number
  );

  return foundStep ? `${number}_${foundStep}` : "";
};
