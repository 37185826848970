import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MeasuredBy from "../../../molecules/MeasuredBy";
import { activeStylesContinue, resultScreenUI } from "../../../../configs";

import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";

import { removeLocalStore } from "../../../../store/localStoreUtils";
import { capitalizeFirstLetter, lowerCaseFirstLetter } from "../../../../utils";
import { brandsArray } from "../../../../configs/configLoader";

import "./index.css";

type SelectorSizeType = "unfocused" | "focused" | "unavailable";

interface IPropsShoeResult {
  step: any;
  restart: () => void;
  recommendation: any | null;
}

const ShoeResult: FC<IPropsShoeResult> = ({
  step,
  restart,
  recommendation,
}) => {
  const { t } = useTranslation("components/results/result");

  const fit_feedbacks = [t("size.size1"), t("size.size2"), t("size.size3")];

  const [selectedSize, setSelectedSize] = useState<any | null>(null);

  const [goodSize, setGoodSize] = useState<any | null>(null);
  const [smallerSize, setSmallerSize] = useState<any | null>(null);
  const [biggerSize, setBiggerSize] = useState<any | null>(null);

  useEffect(() => {
    const sizes = ["good", "smaller", "bigger"] as const;

    sizes.forEach((size) => {
      const data = recommendation?.data?.recommendation?.[size];
      if (data) {
        const setter =
          size === "good"
            ? setGoodSize
            : size === "smaller"
            ? setSmallerSize
            : setBiggerSize;

        setter({
          label: data.label,
          reference: data.reference,
          variant_id: data.reference,
          possible: size === "good" ? 10 : 0,
        });
      }
    });
  }, [recommendation]);

  useEffect(() => {
    if (goodSize && goodSize?.variant_id) {
      setSelectedSize(goodSize);
    }
  }, [goodSize]);

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const getActualDescription = () => {
    if (selectedSize?.variant_id) {
      switch (selectedSize?.variant_id) {
        case smallerSize?.variant_id === selectedSize?.variant_id:
          return selectedSize?.possible === 0
            ? "description.shoe.size_down.not_possible"
            : "description.shoe.size_down.ideal";
        case goodSize?.variant_id === selectedSize?.variant_id:
          return "description.shoe.normal.ideal";
        case biggerSize?.variant_id === selectedSize?.variant_id:
          return selectedSize?.possible === 0
            ? "description.shoe.size_up.not_possible"
            : "description.shoe.size_up.ideal";
        default:
          return "description.shoe.normal.ideal";
      }
    } else {
      return "description.shoe.normal.ideal";
    }
  };

  const getSelectorSizeStyles = (type: SelectorSizeType, variant: string) => {
    const sizeStyles = resultScreenUI.sizeSelector[type]!;

    const cornerBorderWidth = "2px";

    const borderColor = type === "focused" ? "#44883F" : "#E9E9E9";
    const borderRadius = "4px";

    const selectorSizeStyles = {
      backgroundColor: "#FFF",
      color: "#2E2E2E",
      fontWeight: 600,
      fontSize: "11px",
      textTransform:
        sizeStyles.textTransform as React.CSSProperties["textTransform"],
      borderTopLeftRadius:
        smallerSize?.variant_id && variant === "0" ? borderRadius : 0,
      borderBottomLeftRadius:
        smallerSize?.variant_id && variant === "0" ? borderRadius : 0,
      borderTopRightRadius:
        biggerSize?.variant_id && variant === "2" ? borderRadius : 0,
      borderBottomRightRadius:
        biggerSize?.variant_id && variant === "2" ? borderRadius : 0,
      borderWidthStyle: sizeStyles.borderWidth,
      borderTop: `${sizeStyles.borderWidth} solid ${borderColor}`,
      borderBottom: `${sizeStyles.borderWidth} solid ${borderColor}`,
      borderLeft: `${cornerBorderWidth} solid ${borderColor}`,
      borderRight: `${cornerBorderWidth} solid ${borderColor}`,
    };

    return selectorSizeStyles;
  };

  const firstSelectorStyles =
    smallerSize?.variant_id === selectedSize?.variant_id
      ? getSelectorSizeStyles("focused", "0")
      : getSelectorSizeStyles("unfocused", "0");

  const secondSelectorStyles =
    goodSize?.variant_id === selectedSize?.variant_id
      ? getSelectorSizeStyles("focused", "1")
      : getSelectorSizeStyles("unfocused", "1");

  const thirdSelectorStyles =
    biggerSize?.variant_id === selectedSize?.variant_id
      ? getSelectorSizeStyles("focused", "2")
      : getSelectorSizeStyles("unfocused", "2");

  const skipTextTransform = resultScreenUI.restartCTA
    .textTransform as React.CSSProperties["textTransform"];

  const sendMid = () => {
    window.parent.postMessage(
      { data: "mid", mid: localStorage.getItem("mid") },
      "*"
    );
  };

  const clearMid = () => {
    window.parent.postMessage({ action: "clearMid" }, "*");
  };

  const addToCart = () => {
    console.log("addToCart called:", selectedSize?.variant_id);
    window.parent.postMessage(
      {
        action: "addToCart",
        variantId: selectedSize?.variant_id,
      },
      "*"
    );
  };

  const closeIframe = () => {
    window.parent.postMessage({ action: "closeIframe" }, "*");
  };

  return (
    <div
      className="result"
      style={{
        marginTop: "20px",
      }}
    >
      <div className="size">
        <h1
          style={{
            color: "#2E2E2E",
            fontSize: resultScreenUI.recommendedSize
              .fontSize as React.CSSProperties["fontSize"],
            fontWeight: resultScreenUI.recommendedSize
              .fontWeight as React.CSSProperties["fontWeight"],
            textAlign: resultScreenUI.recommendedSize
              .textAlign as React.CSSProperties["textAlign"],
            marginTop: "10px",
            marginBottom: "10px",
            minHeight: "85px",
          }}
        >
          {selectedSize?.label || ""}
        </h1>
        <div className="size-name">
          {goodSize?.variant_id === selectedSize?.variant_id && (
            <>
              <h2
                style={{
                  color: "#44883F",
                  fontSize: resultScreenUI.subtitles
                    .fontSize as React.CSSProperties["fontSize"],
                  fontWeight: resultScreenUI.subtitles
                    .fontWeight as React.CSSProperties["fontWeight"],
                  textAlign: resultScreenUI.subtitles
                    .textAlign as React.CSSProperties["textAlign"],
                }}
              >
                {t("size.title")}
              </h2>
              <CheckCircleIcon
                style={{
                  color: "#44883F",
                }}
              />
            </>
          )}
        </div>
        <div className="sizes-menu">
          <div
            className={`menu-item ${
              smallerSize?.variant_id === selectedSize?.variant_id && "active"
            }`}
            onClick={() => {
              // if (!!smallerSize) setSelectedVariant("0");
              if (!!smallerSize) setSelectedSize(smallerSize);
            }}
            style={{
              ...firstSelectorStyles,
              cursor: !smallerSize ? "default" : "pointer",
              textTransform:
                firstSelectorStyles?.textTransform === "capitalize"
                  ? "none"
                  : firstSelectorStyles?.textTransform,
            }}
          >
            <p>
              {!smallerSize
                ? ""
                : firstSelectorStyles?.textTransform === "capitalize"
                ? capitalizeFirstLetter(fit_feedbacks[0])
                : smallerSize?.variant_id
                ? fit_feedbacks[0]
                : ""}
            </p>
          </div>
          <div
            className={`menu-item ${
              goodSize?.variant_id === selectedSize?.variant_id && "active"
            }`}
            onClick={() => {
              if (!!goodSize) setSelectedSize(goodSize);
            }}
            style={{
              ...secondSelectorStyles,
              cursor: "pointer",
              textTransform:
                secondSelectorStyles?.textTransform === "capitalize"
                  ? "none"
                  : secondSelectorStyles?.textTransform,
            }}
          >
            <p>
              {secondSelectorStyles?.textTransform === "capitalize"
                ? capitalizeFirstLetter(fit_feedbacks[1])
                : fit_feedbacks[1]}
            </p>
          </div>
          <div
            className={`menu-item ${
              biggerSize?.variant_id === selectedSize?.variant_id && "active"
            }`}
            onClick={() => {
              // if (!!biggerSize) setSelectedVariant("2");
              if (!!biggerSize) setSelectedSize(biggerSize);
            }}
            style={{
              ...thirdSelectorStyles,
              cursor: !biggerSize ? "default" : "pointer",
              textTransform:
                thirdSelectorStyles?.textTransform === "capitalize"
                  ? "none"
                  : thirdSelectorStyles?.textTransform,
            }}
          >
            <p>
              {!biggerSize
                ? ""
                : thirdSelectorStyles?.textTransform === "capitalize"
                ? capitalizeFirstLetter(fit_feedbacks[2])
                : biggerSize?.variant_id
                ? fit_feedbacks[2]
                : ""}
            </p>
          </div>
        </div>
      </div>
      <div
        className="result-body-text"
        style={{
          borderBottom: "none",
          justifyContent: resultScreenUI.Description
            .textAlign as React.CSSProperties["justifyContent"],
          backgroundColor: "transparent",
          paddingBottom: 0,
          margin: brandDefined?.name === "SRP" ? "32px 0" : "15px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div
            style={{
              color: "#2E2E2E",
              fontSize:
                brandDefined?.name === "SRP"
                  ? "14px"
                  : (resultScreenUI.Description
                      .fontSize as React.CSSProperties["fontSize"]),
              fontWeight: resultScreenUI.Description
                .fontWeight as React.CSSProperties["fontWeight"],
              textAlign: "left",
              lineHeight: "20px",
              display: "flex",
              alignItems: "center",
              marginBottom: "52px",
              minHeight: "60px",
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t(getActualDescription()).replace(
                  "[S]",
                  selectedSize?.label.replace("-", "&#8209;") || ""
                ),
              }}
            />
          </div>
        </div>
      </div>
      {selectedSize && selectedSize?.variant_id && (
        <button
          type="button"
          className="continue-button step-bottom-result"
          onClick={() => {
            if (selectedSize?.variant_id) {
              addToCart();
              sendMid();
              closeIframe();
            }
          }}
          style={{
            ...activeStylesContinue,
            textTransform:
              activeStylesContinue.textTransform === "capitalize"
                ? "none"
                : activeStylesContinue.textTransform,

            backgroundColor: "#2E2E2E",
            fontWeight: 700,
            fontSize: "14px",
            color: "#FFF",
            borderRadius: "5px",
          }}
        >
          {activeStylesContinue.textTransform === "capitalize" &&
          brandDefined?.name !== "SRP"
            ? capitalizeFirstLetter(
                t("button").replace("[S]", selectedSize?.label || "")
              )
            : t("button").replace("[S]", selectedSize?.label || "")}
        </button>
      )}
      <span
        className="skip"
        onClick={() => {
          clearMid();
          restart();

          removeLocalStore("mid");
          const urlParams = new URLSearchParams(window.location.search);
          removeLocalStore(`${urlParams.get("pid")}`);
        }}
        style={{
          backgroundColor: "transparent",
          fontWeight: 400,
          fontSize: "13px",
          color: "#2E2E2E",
          textTransform: "capitalize",
          textDecoration: resultScreenUI.restartCTA
            .textDecoration as React.CSSProperties["textDecoration"],
          height: resultScreenUI.restartCTA.borderWidth ? "50px" : "auto",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        {skipTextTransform === "capitalize"
          ? capitalizeFirstLetter(t("skip"))
          : t("skip")}
      </span>
      <MeasuredBy step={step} />
    </div>
  );
};

export default ShoeResult;
