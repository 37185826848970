import { API_ENDPOINT, GENDERS } from "../constants/modal";
import {
  IPushAnalytics,
  IPushAnalyticsResponse,
  IUserLocation,
  IUserPushAnalytics,
} from "../types/analytics";
import {
  questionMalePushAnswersSchema,
  questionFemalePushAnswersSchema,
} from "../validation/pushAnswers";

type MeasureType = "question" | "scan";

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const isOnline = () => {
  return navigator.onLine;
};

export const newUser = async (domain: string, retries = 3, delay = 50) => {
  let attempt = 0;

  if (!isOnline()) {
    attempt++;
    if (attempt > retries) {
      console.log("No internet connection after retries");
    }
    await new Promise((resolve) => setTimeout(resolve, delay));
  }

  if (domain) {
    while (attempt <= retries) {
      try {
        localStorage.setItem("domain", domain);

        const response = await fetch(`${API_ENDPOINT}new-user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            retailer: domain,
            device_id: "abcdefghiju",
          }),
        });

        if (!response.ok) {
          // throw new Error(`Server error: ${response.status}`);
          return false;
        }

        const res = await response.json();

        const uid = res["user_id"];
        if (uid) {
          localStorage.setItem("uid", uid);
          return uid;
        } else {
          throw new Error("No user_id in response");
        }
      } catch (error) {
        attempt++;
        if (attempt > retries) {
          throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  } else {
    console.warn("[Kleep] Missing domain in parameters");
  }
};

export const recommend = async (
  pid: string | null,
  productStockData: any,
  retries = 3,
  delay = 50
): Promise<boolean | string | null> => {
  let attempt = 0;

  if (pid) {
    while (attempt <= retries) {
      try {
        if (!isOnline()) {
          attempt++;
          if (attempt > retries) {
            console.log("No internet connection after retries");
            return false;
          }
          await new Promise((resolve) => setTimeout(resolve, delay));
          continue;
        }

        const mid = localStorage.getItem("mid") || null;
        if (mid && uuidRegex.test(mid)) {
          const urlParameters = new URLSearchParams(window.location.search);

          let variant_id = urlParameters.get("variantId") || null;
          const domain = urlParameters.get("domain");
          if (!variant_id && domain === "asphalte.com" && productStockData) {
            const asphalteColor = urlParameters.get("asphalteColor") || null;
            if (!asphalteColor) {
              variant_id = productStockData[0].variant_reference;
            } else {
              const filteredStocks = productStockData.filter(
                (item: any) => item?.color === asphalteColor
              );
              if (filteredStocks?.length > 0)
                variant_id = filteredStocks[0].variant_reference;
            }
          }

          const body = {
            measure_public_id: mid,
            product_reference: pid,
            ...(variant_id && { variant_reference_active: variant_id }),
          };
          const response = await fetch(
            `${API_ENDPOINT}recommend?version=1.0.4`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );

          if (!response.ok) {
            console.log(`Server error: ${response.status}`);
            return false;
          }

          const res = await response.json();

          if (res?.recommendation) {
            window.parent.postMessage(
              { data: "mid", mid: localStorage.getItem("mid") },
              "*"
            );
            return res.recommendation;
          } else {
            console.log("No recommendation available");
            return false;
          }
        } else {
          // console.log("Invalid mid or pid");
          return false;
        }
      } catch (error: any) {
        attempt++;
        console.error(`Attempt ${attempt} failed: ${error.message}`);
        if (attempt > retries) {
          return false;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  } else {
    console.warn("[Kleep] Recommendation uncomputable: no pid is found");
  }

  return false;
};

export const newMeasure = async (
  measure_type: MeasureType,
  user_id?: string,
  retailer_customer_tracking_id?: string | null,
  retries = 3,
  delay = 50
) => {
  let attempt = 0;

  const uid = localStorage.getItem("uid") || user_id || null;

  if (!uid || !uuidRegex.test(uid)) {
    console.log("Invalid or missing user ID");
    return false;
  }

  if (uid && measure_type) {
    while (attempt <= retries) {
      try {
        const response = await fetch(`${API_ENDPOINT}new-measure`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: uid,
            measure_type: measure_type,
            retailer_customer_tracking_id,
          }),
        });

        if (!response.ok) {
          throw new Error(`Server error: ${response.status}`);
        }

        const res = await response.json();

        const mid = res["measure_id"];
        if (mid) {
          localStorage.setItem("mid", mid);
          window.parent.postMessage({ data: "mid", mid }, "*");
          return mid;
        } else {
          throw new Error("No measure_id in response");
        }
      } catch (error: any) {
        attempt++;

        if (attempt > retries) {
          return false;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  }

  return false;
};

export const scanCheckMeasurements = async () => {
  const mid = localStorage.getItem("mid") || null;
  if (!mid) {
    return false;
  }

  try {
    const response = await fetch(`${API_ENDPOINT}scan-check-measurements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        measure_id: mid,
      }),
    });

    if (!response.ok) {
      console.log(`Server error: ${response.status}`);
    }

    const res = await response.json();

    return res["completed"] ?? false;
  } catch (error: any) {
    if (
      error.message.includes("Failed to fetch") ||
      error.message.includes("No internet connection")
    ) {
      throw error;
    }

    return false;
  }
};

export const visionCheck = async (user_id: string) => {
  if (!user_id) return;

  try {
    const response = await fetch(`${API_ENDPOINT}shoe/measure/vision/check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          id: user_id,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Server error: ${response.status}`);
    }

    return await response.json();
  } catch (error: any) {
    if (
      error.message.includes("Failed to fetch") ||
      error.message.includes("No internet connection")
    ) {
      throw error;
    }

    throw new Error("Unexpected error in visionCheck");
  }
};

export const questionPushAnswers = async (answers: object) => {
  const mid = localStorage.getItem("mid") || null;
  if (mid && uuidRegex.test(mid)) {
    const body = {
      measure_id: mid,
      answers: answers,
    };

    try {
      const gender = (answers as any).gender;
      if (gender === GENDERS.M) {
        questionMalePushAnswersSchema.parse(body);
      } else if (gender === GENDERS.F) {
        questionFemalePushAnswersSchema.parse(body);
      } else {
        throw new Error("Invalid gender value");
      }

      const success = await fetch(`${API_ENDPOINT}question-push-trigger`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => res)
        .catch((e) => {
          console.log("Error: questionPushAnswersAndTrigger - ", e);
          return false;
        });

      return !!success;
    } catch (error) {
      console.error("Validation Error:", error);
      return false;
    }
  } else {
    return false;
  }
};

export const checkStocks = async (domain: string, pid: string | null) => {
  if (pid) {
    const success = await fetch(`${API_ENDPOINT}stocks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        retailer_domain: domain,
        product_reference: pid,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.data) {
          return res?.data;
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("Error: recommend - ", e);
        return null;
      });
    return success;
  } else {
    return false;
  }
};

export const findSimilarProducts = async (
  domain: string,
  pid: string,
  variant_id: string | null
) => {
  const success = await fetch(`${API_ENDPOINT}similar-products`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      retailer: domain,
      product_reference: pid,
      variant_reference: variant_id,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    })
    .catch((e) => {
      console.log("Error: recommend - ", e);
      return null;
    });
  return success;
};

const getDeviceInfo = () => {
  const type: "mobile" | "tablet" | "desktop" =
    window.innerWidth < 768
      ? "mobile"
      : window.innerWidth < 1024
      ? "tablet"
      : "desktop";
  const model = navigator.userAgent;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  return { type, model, screenWidth, screenHeight };
};

const getBrowserInfo = () => {
  const type = navigator.userAgent;
  const lang = navigator.language || navigator.languages[0];

  return { type, lang };
};

const CACHE_EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours

const getLocationInfo = async (): Promise<IUserLocation> => {
  const cachedLocation = localStorage.getItem("userLocation");
  const cachedTimestamp = localStorage.getItem("locationCacheTimestamp");

  if (cachedLocation && cachedTimestamp) {
    const cacheAge = Date.now() - parseInt(cachedTimestamp, 10);

    if (cacheAge < CACHE_EXPIRATION_TIME) {
      return JSON.parse(cachedLocation);
    }
  }

  try {
    const response = await fetch("https://ipapi.co/json/");
    const locationData = await response.json();

    const userLocation: IUserLocation = {
      ip: locationData.ip || "unknown",
      country: locationData.country_name || "unknown",
      region: locationData.region || "unknown",
      timezone: locationData.timezone || "unknown",
    };

    localStorage.setItem("userLocation", JSON.stringify(userLocation));
    localStorage.setItem("locationCacheTimestamp", Date.now().toString());

    return userLocation;
  } catch (error) {
    console.error("Failed to fetch location data:", error);

    return {
      ip: "unknown",
      country: "unknown",
      region: "unknown",
      timezone: "unknown",
    };
  }
};

export const pushAnalytics = async ({
  event_type,
  event_name,
  data,
}: IPushAnalytics) => {
  const device = getDeviceInfo();
  const browser = getBrowserInfo();
  const location = await getLocationInfo();

  const userInfo: IUserPushAnalytics = {
    device,
    browser,
    location,
  };

  const domain = localStorage.getItem("domain");
  const mid = localStorage.getItem("mid");
  const userId = localStorage.getItem("uid");

  if (!domain)
    console.warn(
      `[Kleep] Cannot track event '${event_name}': no domain defined`
    );
  else {
    const hostname = window.location.hostname;
    const is_test = hostname.includes("localhost") || hostname.includes("dev");

    const bodyInfo: IPushAnalyticsResponse = {
      event: event_name,
      session_id: "",
      user_id: userId,
      measure_id: mid,
      customer_id: null,
      retailer: domain,
      timestamp: Date.now(),
      data: {
        is_test,
        event_type,
        event_name,
        user: userInfo,
        data,
      },
    };

    try {
      const response = await fetch(`${API_ENDPOINT}analytics/push`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyInfo),
      });

      if (!response.ok) {
        console.log(`Server error: ${response.status}`);
      }

      const res = await response.json();

      return res["completed"] ?? false;
    } catch (error: any) {
      if (
        error.message.includes("Failed to fetch") ||
        error.message.includes("No internet connection")
      ) {
        console.error("Network error occurred:", error.message);
        return false;
      }

      console.error("Unexpected error occurred:", error);
      return false;
    }
  }
};

export const shoeRecommend = async (user_id: string, product_id: string) => {
  try {
    if (user_id) {
      const success = await fetch(
        `${API_ENDPOINT}shoe/recommendation/recommend`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              user: {
                id: user_id,
              },
              product: {
                reference: product_id,
              },
            },
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log("TCL: shoeRecommend -> res", res);
          return res;
        })
        .catch((e) => {
          console.log("Error: shoeRecommend - ", e);
          return false;
        });
      return success;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error with shoeRecommend:", error);
    return false;
  }
};
