import { SvgIcon, SvgIconProps } from "@mui/material";

const InfoStyledIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="10" r="10" fill={props?.fill || "#002D18"} />
    <path
      d="M9.05 15V8H10.17V15H9.05ZM9.624 6.376C9.43733 6.376 9.274 6.306 9.134 6.166C8.994 6.026 8.924 5.86267 8.924 5.676C8.924 5.48 8.994 5.31667 9.134 5.186C9.274 5.046 9.43733 4.976 9.624 4.976C9.82 4.976 9.98333 5.046 10.114 5.186C10.254 5.31667 10.324 5.48 10.324 5.676C10.324 5.86267 10.254 6.026 10.114 6.166C9.98333 6.306 9.82 6.376 9.624 6.376Z"
      fill="white"
    />
  </SvgIcon>
);

export default InfoStyledIcon;
