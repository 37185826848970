import { FC } from "react";
import { MenuItem, Select } from "@mui/material";

import { BASE_SIZES } from "../../../../constants/modal";
import { font, femaleBraScreenUI } from "../../../../configs";

import RotatedArrowIcon from "../RotatedArrowIcon";

import "./index.css";

interface IPropsBaseSizeSelector {
  sizeCountry: string;
  setSizeCountry: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BaseSizeSelector: FC<IPropsBaseSizeSelector> = ({
  sizeCountry,
  setSizeCountry,
  open,
  setOpen,
}) => {
  const fontFamily = `${font}, sans-serif`;

  const langDropdownStyles = {
    color: femaleBraScreenUI.sizeSystem.closed.fontColor,
    borderColor: femaleBraScreenUI.sizeSystem.closed.borderColor,
    borderWidth: femaleBraScreenUI.sizeSystem.closed.borderWidth,
    borderRadius: femaleBraScreenUI.sizeSystem.closed.borderRadius,
  };

  return (
    <Select
      value={sizeCountry}
      onChange={(event) => {
        setSizeCountry(event.target.value);
      }}
      open={open}
      onClose={() => setOpen(false)}
      className="breasts__select"
      IconComponent={(props) => <RotatedArrowIcon open={open} {...props} />}
      MenuProps={{
        MenuListProps: {
          sx: {
            padding: 0,
            fontFamily,
            textAlign: "center",
            "& .Mui-selected": {
              display: "none",
            },
          },
        },
        PaperProps: {
          sx: {
            fontFamily,
            textAlign: "center",
            border: `${langDropdownStyles.borderWidth} solid ${langDropdownStyles.borderColor}`,
            borderTopColor: "transparent",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            boxShadow: "none !important",
            transform: "translateY(-5px) !important",
            borderBottomLeftRadius: langDropdownStyles.borderRadius,
            borderBottomRightRadius: langDropdownStyles.borderRadius,
            paddingBottom:
              langDropdownStyles.borderRadius === "23px" && open ? "10px" : 0,
          },
        },
      }}
      sx={{
        fontFamily,
        border: `${langDropdownStyles.borderWidth} solid ${langDropdownStyles.borderColor}`,
        borderBottomColor: open
          ? "transparent"
          : langDropdownStyles.borderColor,
        borderRadius: open
          ? `${langDropdownStyles.borderRadius} ${langDropdownStyles.borderRadius} 0 0`
          : langDropdownStyles.borderRadius,
        padding:
          langDropdownStyles.borderRadius === "23px" && open ? "20px 0" : 0,
      }}
    >
      {BASE_SIZES?.map(({ name }) => (
        <MenuItem
          key={name}
          className="breasts__select__item"
          value={name}
          style={{
            fontFamily,
          }}
        >
          <span
            className="breasts__select__item__flag-icons"
            style={{
              color: femaleBraScreenUI.sizeSystem.closed.fontColor,
            }}
          >
            {name}
          </span>
        </MenuItem>
      ))}
    </Select>
  );
};

export default BaseSizeSelector;
