import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "react-responsive";

import H_twh from "../../../../assets/result/H_twh.svg";
import H_ctw from "../../../../assets/result/H_ctw.svg";
import H_ctwh from "../../../../assets/result/H_ctwh.svg";
import H_h from "../../../../assets/result/H_h.svg";
import H_h_unisex from "../../../../assets/result/H_h_unisex.svg";
import H_c from "../../../../assets/result/H_c.svg";

import F_c from "../../../../assets/result/F_c.svg";
import F_cw from "../../../../assets/result/F_cw.svg";
import F_ctwh from "../../../../assets/result/F_ctwh.svg";
import F_wh from "../../../../assets/result/F_wh.svg";
import F_h_unisex from "../../../../assets/result/F_h_unisex.svg";

import "./index.css";

const tagStyles: React.CSSProperties = {
  width: "fit-content",
  height: "auto",
  whiteSpace: "nowrap",
  padding: "2px 10px",
  borderRadius: "3px",
  alignItems: "center",
  textAlign: "center",
  display: "flex",
  position: "relative",
};

const leftArrowStyles: React.CSSProperties = {
  position: "absolute",
  left: "-6px",
  width: 0,
  height: 0,
  borderTop: "7px solid transparent",
  borderBottom: "7px solid transparent",
};

interface IPropsSizeSelector {
  selectedSizeImage: string;
  feedbacks: any;
}

const SizeSelector: FC<IPropsSizeSelector> = ({
  selectedSizeImage,
  feedbacks,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isSmallMobile = useMediaQuery({ maxWidth: 550 });

  const { t } = useTranslation("components/results/result");

  const fit_value_feedbacks = [
    t("size.variations.size6"),
    t("size.variations.size5"),
    t("size.variations.size3"),
    t("size.variations.size8"),
    t("size.variations.size10"),
  ];
  const fit_value_colors = [
    "#FFEEE2",
    "#FFF9E2",
    "#C0E2C3",
    "#FFF9E2",
    "#FFEEE2",
  ];

  const handleTagsByUrl = () => {
    switch (selectedSizeImage) {
      case H_twh:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "100px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.hip],
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${fit_value_colors[feedbacks?.hip]}`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.hip]}
            </div>
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.waist],
                top: "12px",
                left: "4px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.waist]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.waist]}
            </div>
          </div>
        );
      case H_ctw:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "85px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.chest],
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.chest]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.chest]}
            </div>
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.waist],
                top: "30px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.waist]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.waist]}
            </div>
          </div>
        );
      case H_ctwh:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "70px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.chest],
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.chest]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.chest]}
            </div>
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.waist],
                top: "30px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.waist]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.waist]}
            </div>
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.hip],
                top: "40px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${fit_value_colors[feedbacks?.hip]}`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.hip]}
            </div>
          </div>
        );
      case H_h:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "60px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.waist],
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.waist]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.waist]}
            </div>
          </div>
        );
      case H_c:
        return (
          <div
            style={{
              position: "absolute",
              left: "130px",
              bottom: "100px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.chest],
                bottom: "4px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.chest]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.chest]}
            </div>
          </div>
        );
      case H_h_unisex:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "40px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.hip],
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${fit_value_colors[feedbacks?.hip]}`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.hip]}
            </div>
          </div>
        );
      case F_cw:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "80px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.chest],
                top: "4px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.chest]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.chest]}
            </div>
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.waist],
                top: "20px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.waist]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.waist]}
            </div>
          </div>
        );
      case F_wh:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "80px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.hip],
                bottom: "10px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${fit_value_colors[feedbacks?.hip]}`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.hip]}
            </div>
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.waist],
                top: "0px",
                left: "4px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.waist]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.waist]}
            </div>
          </div>
        );
      case F_ctwh:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "70px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.chest],
                top: "4px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.chest]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.chest]}
            </div>
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.waist],
                top: "20px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.waist]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.waist]}
            </div>
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.hip],
                top: "38px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${fit_value_colors[feedbacks?.hip]}`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.hip]}
            </div>
          </div>
        );
      case F_c:
        return (
          <div
            style={{
              position: "absolute",
              left: "134px",
              bottom: "90px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.chest],
                bottom: "4px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${
                    fit_value_colors[feedbacks?.chest]
                  }`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.chest]}
            </div>
          </div>
        );
      case F_h_unisex:
        return (
          <div
            style={{
              position: "absolute",
              left: "140px",
              bottom: "25px",
            }}
            className="tag-container"
          >
            <div
              style={{
                ...tagStyles,
                backgroundColor: fit_value_colors[feedbacks?.hip],
                bottom: "4px",
              }}
              className="tag-style"
            >
              <span
                style={{
                  ...leftArrowStyles,
                  borderRight: `12px solid ${fit_value_colors[feedbacks?.hip]}`,
                }}
              />
              {fit_value_feedbacks[feedbacks?.hip]}
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <div
      style={{
        position: "relative",
        right: isSmallMobile ? 0 : !isMobile ? "20px" : "-10px",
      }}
    >
      {selectedSizeImage && (
        <img
          src={selectedSizeImage}
          alt="result"
          style={{
            height:
              selectedSizeImage === H_twh || selectedSizeImage === F_wh
                ? "168px"
                : "202px",
          }}
        />
      )}
      {handleTagsByUrl()}
    </div>
  );
};

export default SizeSelector;
