import { brandsArray, getConfig } from "./configLoader";

// Get the configuration
const config = getConfig();

export const uxGender = config.ux.gender;
export const uxRoute = config.ux.route;
export const uxConsentScreen = config.ux.consentScreen;
export const uxFitFeedbacks = config.ux.fitFeedbacks;

export const font = config.ui.all.font;
export const titleStyles = config.ui.all.titles;
export const subtitlesStyles = config.ui.all.subtitles;
export const ctaStyles = config.ui.all.cta;
export const policityStyles = config.ui.all.Politicy;

export const scanCTAStyle = config.ui.questions.scanCTA;

export const welcomeScreenUI = config.ui[1];
export const choiceScreenUI = config.ui[2];
export const bodyScanScreenUI = config.ui[3];
export const morphoUIStyles = config.ui["4/5/6"];
export const femaleBraScreenUI = config.ui["6-2"];
export const resultScreenUI = config.ui[7];

const urlParameters = new URLSearchParams(window.location.search);
const domain = urlParameters.get("domain");

const brandDefined = brandsArray.find((brand) =>
  domain ? brand.domains.includes(domain) : null
);

const convertPercentageToOpacity = (percentage: string): number => {
  return parseInt(percentage) / 100;
};

const backgroundColorStyles = (ctaStyles.unfocused as any)?.backgroundOpacity
  ? `rgba(
  ${parseInt(ctaStyles.unfocused.backgroundColor.slice(1, 3), 16)},
  ${parseInt(ctaStyles.unfocused.backgroundColor.slice(3, 5), 16)},
  ${parseInt(ctaStyles.unfocused.backgroundColor.slice(5, 7), 16)},
  ${convertPercentageToOpacity((ctaStyles.unfocused as any)?.backgroundOpacity)}
  )`
  : (ctaStyles.unfocused
      .backgroundColor as React.CSSProperties["backgroundColor"]);

const fontColorStyles = (ctaStyles.unfocused as any)?.fontOpacity
  ? `rgba(
  ${parseInt(ctaStyles.unfocused.fontColor.slice(1, 3), 16)},
  ${parseInt(ctaStyles.unfocused.fontColor.slice(3, 5), 16)},
  ${parseInt(ctaStyles.unfocused.fontColor.slice(5, 7), 16)},
  ${convertPercentageToOpacity((ctaStyles.unfocused as any)?.fontOpacity)}
  )`
  : (ctaStyles.unfocused.fontColor as React.CSSProperties["color"]);

// Custom styles for buttons
export const activeStylesContinue = {
  backgroundColor: ctaStyles.focused
    .backgroundColor as React.CSSProperties["backgroundColor"],
  fontWeight: ctaStyles.focused.fontWeight as React.CSSProperties["fontWeight"],
  fontSize: ctaStyles.focused.fontSize as React.CSSProperties["fontSize"],
  color: ctaStyles.focused.fontColor as React.CSSProperties["color"],
  textTransform: ctaStyles.focused
    .textTransform as React.CSSProperties["textTransform"],
  borderRadius: ctaStyles.focused
    .borderRadius as React.CSSProperties["borderRadius"],
  borderWidth: ctaStyles.focused
    .borderWidth as React.CSSProperties["borderWidth"],
  borderColor: ctaStyles.focused
    .borderColor as React.CSSProperties["borderColor"],
  borderStyle: "solid",
  fontFamily: `${font}, sans-serif`,
  letterSpacing:
    brandDefined?.name === "Mister K"
      ? "3.2px"
      : brandDefined?.name !== "SRP" && brandDefined?.name !== "Mister K"
      ? "0.6px"
      : 0,
};

export const disabledStylesContinue = {
  backgroundColor: backgroundColorStyles,
  fontWeight: ctaStyles.unfocused
    .fontWeight as React.CSSProperties["fontWeight"],
  fontSize: ctaStyles.unfocused.fontSize as React.CSSProperties["fontSize"],
  color: fontColorStyles,
  textTransform: ctaStyles.unfocused
    .textTransform as React.CSSProperties["textTransform"],
  borderRadius: ctaStyles.unfocused
    .borderRadius as React.CSSProperties["borderRadius"],
  borderTopWidth: ctaStyles.unfocused
    .borderWidth as React.CSSProperties["borderTopWidth"],
  borderBottomWidth: ctaStyles.unfocused
    .borderWidth as React.CSSProperties["borderBottomWidth"],
  borderLeftWidth: ctaStyles.unfocused
    .borderWidth as React.CSSProperties["borderLeftWidth"],
  borderRightWidth: ctaStyles.unfocused
    .borderWidth as React.CSSProperties["borderRightWidth"],
  borderTopColor: ctaStyles.unfocused
    .borderColor as React.CSSProperties["borderTopColor"],
  borderBottomColor: ctaStyles.unfocused
    .borderColor as React.CSSProperties["borderBottomColor"],
  borderLeftColor: ctaStyles.unfocused
    .borderColor as React.CSSProperties["borderLeftColor"],
  borderRightColor: ctaStyles.unfocused
    .borderColor as React.CSSProperties["borderRightColor"],
  borderStyle: "solid",
  cursor: "default",
  fontFamily: `${font}, sans-serif`,
  letterSpacing:
    brandDefined?.name === "Mister K"
      ? "3.2px"
      : brandDefined?.name !== "SRP" && brandDefined?.name !== "Mister K"
      ? "0.6px"
      : 0,
};
