import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { activeStylesContinue, font } from "../../configs";
import { GENDERS } from "../../constants/modal";

import { Help as HelpIcon } from "@mui/icons-material";
import { Popper, Typography, Paper, ClickAwayListener } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils";

import "./index.css";
import { handleAnalytics } from "../../utils/tracking";

interface IPropsQrcodeMobile {
  loading?: boolean;
  url?: string;
  gender: string | undefined;
  subtitleStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
}

const QrcodeMobile: FC<IPropsQrcodeMobile> = ({
  loading,
  url,
  gender,
  subtitleStyles,
}) => {
  const { t } = useTranslation("components/qrcode/mobile");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "info-popper" : undefined;

  const openScan = () => {
    if (!loading) {
      handleAnalytics(
        "action",
        "scan_clicked",
        null
      )
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className="mobile-qr-container">
      <video muted className="qrcode-video" playsInline autoPlay loop>
        <source
          src={
            gender === GENDERS.M
              ? require("../../assets/qrcode/demo-male.mp4")
              : require("../../assets/qrcode/demo-female.mp4")
          }
          type="video/mp4"
        />
      </video>
      <div className="help-info">
        <HelpIcon />
        <span
          className="link"
          aria-describedby={id}
          onClick={handleClick}
          style={{
            color: `${subtitleStyles.color}`,
            fontFamily: `${font}, sans-serif`,
          }}
        >
          {t("info")}
        </span>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="top">
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Paper
              className="popper-container"
              style={{
                border: `1px solid ${subtitleStyles.color}`,
                fontFamily: `${font}, sans-serif`,
              }}
            >
              <Typography
                style={{
                  color: `${subtitleStyles.color}`,
                  fontFamily: `${font}, sans-serif`,
                }}
              >
                {t("popupText")}
              </Typography>
              <div
                className="popper-triangle"
                style={{
                  borderTop: `10px solid ${subtitleStyles.color}`,
                }}
              />
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
      <button
        type="button"
        className="continue-button"
        onClick={openScan}
        style={{
          ...activeStylesContinue,
          textTransform:
            activeStylesContinue.textTransform === "capitalize"
              ? "none"
              : activeStylesContinue.textTransform,
        }}
      >
        {activeStylesContinue.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("scan"))
          : t("scan")}
      </button>
    </div>
  );
};

export default QrcodeMobile;
